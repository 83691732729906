<template>
    <card-table-search title="Liste des associations" :items="assos" :fields="fields" :actions="actions"/>
</template>

<script>
    import {formatDate}  from '@/util/date';
    import {apiPath}     from '@/util/http';
    import alert         from '@/util/alert';
    import {momentDates} from '@/util/association';

    const CardTableSearch = () => import('@/components/CardTableSearch');
    const EditText        = () => import('@/components/modals/quick_edit/EditText');

    export default {
        name: "AdminAssociations",
        components: {CardTableSearch},
        data() {
            return {
                assos: [],
                fields: [
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'dateInscription',
                        label: "Date d'inscription",
                        sortable: true,
                        formatter: val => formatDate(val)
                    },
                    {
                        key: 'utilisateurs',
                        label: "Membres",
                        sortable: true,
                        formatter: val => val.length
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editAssociationModal(item)
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: 'Supprimer',
                        display: ({item}) => !item.utilisateurs.length,
                        handler: ({item}) => this.deleteAssociation(item)
                    }
                ]
            }
        },
        methods: {
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_assos_admin'))
                    .then(response => this.assos = momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des associations'))
                    .finally(alert.stopLoading);
            },
            editAssociationModal(asso) {
                this.$store.dispatch('modal/create', {
                    component: EditText,
                    props: {
                        value: asso.nom,
                        callback: input => {
                            alert.loading();
                            this.axios.post(apiPath('edit_association_admin', {association: asso.id}), {nom: input})
                                .then(() => this.loadData())
                                .catch(() => this.$toaster.error("Impossible de modifier l'association"))
                                .finally(alert.stopLoading);
                        }
                    }
                });
            },
            deleteAssociation(asso) {
                alert.confirm(
                    'Supprimer l\'association "' + asso.nom + '" ?',
                    () => this.axios.delete(apiPath('delete_association_admin', {association: asso.id}))
                        .then(() => {
                            this.$toaster.success('Association supprimée');
                            this.loadData();
                        })
                        .catch(() => this.$toaster.error("Impossible de supprimer l'association"))
                );
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
